import React from "react";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import { Link, useLocation } from "react-router-dom";
import { RouteItem } from "../config/types";
import { useTranslation } from "react-i18next";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import TakeoutDiningIcon from "@mui/icons-material/TakeoutDining";
import DashboardIcon from "@mui/icons-material/Dashboard";

const drawerWidth = 240;

interface SidebarProps {
  variant: "permanent" | "temporary";
  open: boolean;
  onClose: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ variant, open, onClose }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const routeItems: RouteItem[] = [
    {
      label: t("Dashboard"),
      icon: <DashboardIcon />,
      link: "/dashboard",
    },
    {
      label: t("Profile"),
      icon: <AssignmentIndIcon />,
      link: "/vendor-profile",
    },
    { label: t("Meal"), icon: <RestaurantIcon />, link: "/meal-management" },
    {
      label: t("Preset_Schedule"),
      icon: <BookmarksIcon />,
      link: "/meal-schedule-preset",
    },
    {
      label: t("Calendar"),
      icon: <CalendarMonthIcon />,
      link: "/meal-schedule-overview",
    },
    {
      label: t("Orders"),
      icon: <TakeoutDiningIcon />,
      link: "/vendor-orders",
    },
  ];

  return (
    <Drawer
      variant={variant}
      open={open}
      onClose={onClose}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "#fcf6f1",
          color: "#333",
        },
        ".css-h4y409-MuiList-root": {
          paddingTop: 0,
        },
        ".css-vmpu94-MuiPaper-root-MuiDrawer-paper": {
          borderRight: "none",
        },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <List>
          {routeItems.map((item: RouteItem, index: number) => (
            <ListItem
              key={index}
              disablePadding
              component={Link}
              to={item.link}
              style={{ color: "inherit", textDecoration: "none" }}
              onClick={onClose}
            >
              <ListItemButton
                selected={location.pathname === item.link}
                disableRipple
                sx={{
                  transition: "background-color 0.3s",
                  "&:hover": {
                    backgroundColor: "#FFDAB9",
                    color: "#black",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#FFDAB9",
                    color: "#black",
                    "&:hover": {
                      backgroundColor: "#FFDAB9",
                    },
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color: "black",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
