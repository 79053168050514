import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  Alert,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import TopBar from "../components/TopBar/TopBar";
import onPrimary from "../config/colors";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { confirmResetPassword } from "aws-amplify/auth";

const ResetPasswordPage: React.FC = () => {
  const location = useLocation();
  const username = location.state?.username || "";
  const [code, setCode] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!code.trim() || !newPassword.trim() || !confirmNewPassword.trim()) {
      setError("All fields are required.");
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setError("Passwords do not match.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const confirmationCode = code;
      await confirmResetPassword({ username, confirmationCode, newPassword });
      setSuccessMessage("Password has been reset successfully.");
      navigate("/login");
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else if (
        typeof error === "object" &&
        error !== null &&
        "response" in error
      ) {
        const errResponse = (error as { response: any }).response;
        if (errResponse?.data) {
          setError(errResponse.data);
        } else {
          setError("An unexpected error occurred. Please try again.");
        }
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="landing-page">
      <TopBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "70vh",
          paddingTop: "20vh",
        }}
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            backgroundColor: onPrimary,
            padding: "5vh",
            borderRadius: "15px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              style={{ fontFamily: "Poppins", fontWeight: 600 }}
            >
              Reset Password
            </Typography>
            <Typography
              component="h2"
              variant="subtitle1"
              style={{ fontFamily: "Poppins", fontWeight: 400, marginTop: 16 }}
            >
              Please check your email and enter the verification code along with
              your new password
            </Typography>
            <Box component="form" sx={{ mt: 2 }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Verification Code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="New Password"
                value={newPassword}
                type={showPassword ? "text" : "password"}
                onChange={(e) => setNewPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Confirm New Password"
                value={confirmNewPassword}
                type={showPassword ? "text" : "password"}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onPaste={(e) => {
                  e.preventDefault();
                  setError("Please re-enter your password by typing it in.");
                }}
              />
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading}
                onClick={handleSubmit}
              >
                {loading ? <CircularProgress size={24} /> : "Submit"}
              </Button>
              {error && (
                <Box mt={2}>
                  <Alert severity="error">{error}</Alert>
                </Box>
              )}
              {successMessage && (
                <Box mt={2}>
                  <Alert severity="success">{successMessage}</Alert>
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default ResetPasswordPage;
