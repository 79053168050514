import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  CircularProgress,
  List,
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Star as StarIcon,
  StarBorder as StarBorderIcon,
} from "@mui/icons-material";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { useNavigate } from "react-router-dom";
import apiHelper from "../helper/apiHelper";
import endpoints from "../config/apiConfig";
import { useTranslation } from "react-i18next";

const MealSchedulePresetPage: React.FC = () => {
  const vendorId = localStorage.getItem("vendorId") || "";
  const [presets, setPresets] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [confirmDefaultPresetDialogOpen, setConfirmDefaultPresetDialogOpen] =
    useState<boolean>(false);
  const [selectedPresetId, setSelectedPresetId] = useState<string | null>(null);
  const [defaultPreset, setDefaultPreset] = useState<string>("");
  const [newDefaultPreset, setNewDefaultPreset] = useState<string>("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchPresets = async () => {
      try {
        setLoading(true);
        const presets = await apiHelper(
          endpoints.getPresetSummaries + vendorId,
          {
            method: "GET",
          },
        );
        setPresets(presets);
        const settings = await apiHelper(endpoints.vendorSettings + vendorId, {
          method: "GET",
        });
        setDefaultPreset(settings.defaultPresetId);
      } catch (error) {
        console.error("Failed to fetch presets:", error);
      } finally {
        setLoading(false);
      }
    };

    if (vendorId) {
      fetchPresets();
    }
  }, [vendorId]);

  useEffect(() => {
    const updateSettings = async () => {
      try {
        if (defaultPreset && vendorId) {
          await apiHelper(endpoints.vendorSettings + vendorId, {
            method: "POST",
            body: { defaultPresetId: defaultPreset },
          });
        }
      } catch (error) {
        console.error("Failed to update user settings:", error);
      }
    };

    updateSettings();
  }, [defaultPreset, vendorId]);

  const handleDeletePreset = async () => {
    if (selectedPresetId) {
      try {
        setDeleteDialogOpen(false);
        setLoading(true);
        await apiHelper(endpoints.deletePreset + selectedPresetId, {
          method: "DELETE",
        });
        // Reload presets
        const data = await apiHelper(endpoints.getPresetSummaries + vendorId, {
          method: "GET",
        });
        setPresets(data);
        setSelectedPresetId(null);
        if (data.length === 1) {
          setDefaultPreset(data[0].id);
        }
      } catch (error) {
        console.error("Failed to delete preset:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const openDeleteDialog = (presetId: string) => {
    setSelectedPresetId(presetId);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setSelectedPresetId(null);
  };

  const handleDefaultPresetChange = (presetId: string) => {
    setNewDefaultPreset(presetId);
    setConfirmDefaultPresetDialogOpen(true);
  };

  const confirmDefaultPresetChange = (confirmed: boolean) => {
    if (confirmed) {
      setDefaultPreset(newDefaultPreset);
    }
    setConfirmDefaultPresetDialogOpen(false);
  };

  // Sorting presets with the default preset first, then alphabetically by preset name
  const sortedPresets = presets.sort((a, b) => {
    if (a.id === defaultPreset) return -1;
    if (b.id === defaultPreset) return 1;
    return a.presetName.localeCompare(b.presetName);
  });

  return (
    <Container component="main" maxWidth="lg" sx={{ mt: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography component="h1" variant="h5" sx={{ mr: 1 }}>
          {t("MealSchedulePresetPage.title")}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            navigate(`/add-preset`, {
              state: { setDefault: presets.length === 0 },
            })
          }
          disabled={presets.length >= 5}
        >
          {t("MealSchedulePresetPage.addPreset")}
        </Button>
      </Box>
      {loading ? (
        <CircularProgress sx={{ m: 2 }} />
      ) : sortedPresets.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mt: "20%",
          }}
        >
          <EventBusyIcon
            sx={{ fontSize: 60, color: "gray", marginBottom: 2 }}
          />
          <Typography variant="h6" color="textSecondary">
            {t("MealSchedulePresetPage.noAvailablePresets")}
          </Typography>
        </Box>
      ) : (
        <List sx={{ mt: 2 }}>
          {sortedPresets.map((preset) => (
            <Card key={preset.id} variant="outlined" sx={{ mb: 2 }}>
              <CardContent>
                <Box display="flex" alignItems="center">
                  <IconButton
                    onClick={() => handleDefaultPresetChange(preset.id)}
                    color={preset.id === defaultPreset ? "primary" : "default"}
                  >
                    {preset.id === defaultPreset ? (
                      <StarIcon />
                    ) : (
                      <StarBorderIcon />
                    )}
                  </IconButton>
                  <Typography variant="h6">{preset.presetName}</Typography>
                </Box>
              </CardContent>
              <CardActions>
                <Button
                  startIcon={<EditIcon />}
                  onClick={() => navigate(`/edit-preset/${preset.id}`)}
                >
                  {t("MealSchedulePresetPage.edit")}
                </Button>
                <Button
                  startIcon={<DeleteIcon />}
                  onClick={() => openDeleteDialog(preset.id)}
                  color="error"
                  disabled={presets.length === 1}
                >
                  {t("MealSchedulePresetPage.delete")}
                </Button>
              </CardActions>
            </Card>
          ))}
        </List>
      )}
      <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog} fullWidth>
        <DialogTitle>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {t("MealSchedulePresetPage.deletePreset")}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>{t("MealSchedulePresetPage.deleteConfirm")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            {t("MealSchedulePresetPage.cancel")}
          </Button>
          <Button onClick={handleDeletePreset} color="primary">
            {t("MealSchedulePresetPage.delete")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmDefaultPresetDialogOpen}
        onClose={() => setConfirmDefaultPresetDialogOpen(false)}
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {t("MealSchedulePresetPage.confirmDefaultPreset")}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            {t("MealSchedulePresetPage.confirmDefaultPresetText")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => confirmDefaultPresetChange(false)}
            color="primary"
          >
            {t("MealSchedulePresetPage.cancel")}
          </Button>
          <Button
            onClick={() => confirmDefaultPresetChange(true)}
            color="primary"
          >
            {t("MealSchedulePresetPage.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default MealSchedulePresetPage;
