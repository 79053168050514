import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  CircularProgress,
  Tabs,
  Tab,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import apiHelper from "../helper/apiHelper";
import endpoints from "../config/apiConfig";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Meal, MealSchedulePresetResponse } from "../config/types";
import MealSection from "../components/MealSection";
import {
  initializePresetDays,
  preparePresetMealData,
} from "../helper/mealSchedulePresetUtils";
import { useTranslation } from "react-i18next";

interface EditMealSchedulePresetPageProps {
  mode: "add" | "edit";
}

const EditMealSchedulePresetPage: React.FC<EditMealSchedulePresetPageProps> = ({
  mode,
}) => {
  const { t } = useTranslation();
  const vendorId = localStorage.getItem("vendorId") || "";
  const location = useLocation();
  const { presetId } = useParams<{ presetId: string }>();
  const navigate = useNavigate();

  const [meals, setMeals] = useState<Meal[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [weeklySchedule, setWeeklySchedule] =
    useState<MealSchedulePresetResponse>({
      presetName: "",
      breakfastMeals: initializePresetDays(),
      lunchMeals: initializePresetDays(),
      dinnerMeals: initializePresetDays(),
    });
  const [selectedMeals, setSelectedMeals] = useState<
    Record<string, string[][]>
  >({
    BREAKFAST: Array.from({ length: 7 }, () => []),
    LUNCH: Array.from({ length: 7 }, () => []),
    DINNER: Array.from({ length: 7 }, () => []),
  });
  const [presetName, setPresetName] = useState<string>("");
  const [saving, setSaving] = useState<boolean>(false);
  const [currentDay, setCurrentDay] = useState<number>(0);
  const [clearDialogOpen, setClearDialogOpen] = useState<boolean>(false);
  const [copyDialogOpen, setCopyDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchMealsAndPreset = async () => {
      try {
        setLoading(true);
        const data = await apiHelper(endpoints.fetchMeals + vendorId, {
          method: "GET",
        });
        const mealData = data.filter(
          (meal: Meal) => meal.active && meal.available,
        );
        setMeals(mealData);

        if (mode === "edit" && presetId) {
          const data: MealSchedulePresetResponse = await apiHelper(
            endpoints.getPresetDetail + presetId,
            {
              method: "GET",
            },
          );
          setPresetName(data.presetName);
          setWeeklySchedule({
            presetName: data.presetName,
            breakfastMeals: initializePresetDays(data.breakfastMeals),
            lunchMeals: initializePresetDays(data.lunchMeals),
            dinnerMeals: initializePresetDays(data.dinnerMeals),
          });
          const daysOfWeek = [
            "SUNDAY",
            "MONDAY",
            "TUESDAY",
            "WEDNESDAY",
            "THURSDAY",
            "FRIDAY",
            "SATURDAY",
          ];

          setSelectedMeals({
            BREAKFAST: daysOfWeek.map((day) =>
              data.breakfastMeals[day].map((meal: any) => meal.mealId),
            ),
            LUNCH: daysOfWeek.map((day) =>
              data.lunchMeals[day].map((meal: any) => meal.mealId),
            ),
            DINNER: daysOfWeek.map((day) =>
              data.dinnerMeals[day].map((meal: any) => meal.mealId),
            ),
          });
        }
      } catch (error) {
        console.error("Failed to fetch meals and preset:", error);
      } finally {
        setLoading(false);
      }
    };

    if (vendorId) {
      fetchMealsAndPreset();
    }
  }, [presetId, mode, vendorId]);

  const handleMealSelect = (
    mealType: "BREAKFAST" | "LUNCH" | "DINNER",
    mealId: string,
    dayIndex: number,
  ) => {
    setSelectedMeals((prev) => {
      const updated = { ...prev };
      const dayMeals = updated[mealType][dayIndex];
      if (dayMeals.includes(mealId)) {
        updated[mealType][dayIndex] = dayMeals.filter((id) => id !== mealId);
      } else {
        updated[mealType][dayIndex] = [...dayMeals, mealId];
      }
      return updated;
    });
  };

  const handleCountChange = (
    dayIndex: number,
    mealType: "BREAKFAST" | "LUNCH" | "DINNER",
    mealId: string,
    count: number,
  ) => {
    const mealKey = mealType.toLowerCase() + "Meals";
    const daysOfWeek = [
      "SUNDAY",
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
    ];
    const dayOfWeek = daysOfWeek[dayIndex];

    setWeeklySchedule((prev: any) => {
      const updatedMeals = prev[mealKey][dayOfWeek].map((meal: any) =>
        meal.mealId === mealId ? { ...meal, count: count } : meal,
      );

      if (!updatedMeals.find((meal: any) => meal.mealId === mealId)) {
        updatedMeals.push({ mealId, count, mealType });
      }

      return {
        ...prev,
        [mealKey]: {
          ...prev[mealKey],
          [dayOfWeek]: updatedMeals,
        },
      };
    });
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      const breakfastMeals = preparePresetMealData(
        weeklySchedule.breakfastMeals,
        selectedMeals,
        "BREAKFAST",
      );
      const lunchMeals = preparePresetMealData(
        weeklySchedule.lunchMeals,
        selectedMeals,
        "LUNCH",
      );
      const dinnerMeals = preparePresetMealData(
        weeklySchedule.dinnerMeals,
        selectedMeals,
        "DINNER",
      );

      if (mode === "add") {
        const data = await apiHelper(endpoints.addSchedulePreset, {
          method: "POST",
          body: {
            vendorId,
            presetName,
            breakfastMeals,
            lunchMeals,
            dinnerMeals,
          },
        });
        if (location.state?.setDefault) {
          await apiHelper(endpoints.vendorSettings + vendorId, {
            method: "POST",
            body: { defaultPresetId: data.presetId },
          });
        }
      } else {
        await apiHelper(endpoints.saveSchedulePreset + presetId, {
          method: "PUT",
          body: {
            vendorId,
            presetName,
            breakfastMeals,
            lunchMeals,
            dinnerMeals,
          },
        });
      }

      navigate(`/meal-schedule-preset`);
    } catch (error) {
      console.error("Failed to save meal schedule preset:", error);
    } finally {
      setSaving(false);
    }
  };

  const handleClearSchedule = () => {
    setSelectedMeals({
      BREAKFAST: Array.from({ length: 7 }, () => []),
      LUNCH: Array.from({ length: 7 }, () => []),
      DINNER: Array.from({ length: 7 }, () => []),
    });
    setWeeklySchedule((prev: any) => ({
      ...prev,
      breakfastMeals: initializePresetDays({}),
      lunchMeals: initializePresetDays({}),
      dinnerMeals: initializePresetDays({}),
    }));
    setClearDialogOpen(false);
  };

  const handleCopySchedule = () => {
    const prevDayIndex = currentDay - 1;
    setSelectedMeals((prev) => {
      const updated = { ...prev };
      updated.BREAKFAST[currentDay] = [...updated.BREAKFAST[prevDayIndex]];
      updated.LUNCH[currentDay] = [...updated.LUNCH[prevDayIndex]];
      updated.DINNER[currentDay] = [...updated.DINNER[prevDayIndex]];
      return updated;
    });

    setWeeklySchedule((prev: any) => {
      const daysOfWeek = [
        "SUNDAY",
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
      ];
      const dayOfWeek = daysOfWeek[currentDay];
      const prevDayOfWeek = daysOfWeek[prevDayIndex];

      return {
        ...prev,
        breakfastMeals: {
          ...prev.breakfastMeals,
          [dayOfWeek]: [...prev.breakfastMeals[prevDayOfWeek]],
        },
        lunchMeals: {
          ...prev.lunchMeals,
          [dayOfWeek]: [...prev.lunchMeals[prevDayOfWeek]],
        },
        dinnerMeals: {
          ...prev.dinnerMeals,
          [dayOfWeek]: [...prev.dinnerMeals[prevDayOfWeek]],
        },
      };
    });
    setCopyDialogOpen(false);
  };

  const hasSelections = () =>
    Object.values(selectedMeals).some((mealType) =>
      mealType.some((dayMeals) => dayMeals.length > 0),
    );

  const previousDayHasSelections = (currentDay: number) => {
    if (currentDay > 0) {
      return Object.values(selectedMeals).some(
        (mealType) => mealType[currentDay - 1].length > 0,
      );
    } else {
      return false;
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container component="main" maxWidth="lg" sx={{ mt: 4 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography component="h1" variant="h5">
            {mode === "add"
              ? t("editMealSchedulePresetPage.addPreset")
              : t("editMealSchedulePresetPage.editPreset")}
          </Typography>
          <Box display="flex">
            <Button
              variant="contained"
              color="inherit"
              onClick={() => navigate(`/meal-schedule-preset`)}
            >
              {t("editMealSchedulePresetPage.back")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setCopyDialogOpen(true)}
              sx={{ ml: 1 }}
              disabled={
                saving ||
                currentDay === 0 ||
                !previousDayHasSelections(currentDay)
              }
            >
              {t("editMealSchedulePresetPage.copyPreviousDay")}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => setClearDialogOpen(true)}
              sx={{ ml: 1 }}
              disabled={saving || !hasSelections()}
            >
              {t("editMealSchedulePresetPage.clear")}
            </Button>
            <Tooltip title={t("editMealSchedulePresetPage.presetName")}>
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  disabled={saving || !hasSelections() || !presetName.trim()}
                  sx={{ ml: 1 }}
                >
                  {saving ? (
                    <CircularProgress size={24} />
                  ) : (
                    t("editMealSchedulePresetPage.save")
                  )}
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Box>
        {loading ? (
          <CircularProgress sx={{ m: 2 }} />
        ) : (
          <>
            <TextField
              sx={{ mt: 2 }}
              margin="dense"
              label={t("editMealSchedulePresetPage.presetName")}
              type="text"
              fullWidth
              value={presetName}
              onChange={(e) => setPresetName(e.target.value)}
              required
            />
            <Tabs
              value={currentDay}
              onChange={(_, newValue) => setCurrentDay(newValue)}
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="auto"
              sx={{ mt: 2 }}
              variant="fullWidth"
            >
              {[
                t("editMealSchedulePresetPage.sunday"),
                t("editMealSchedulePresetPage.monday"),
                t("editMealSchedulePresetPage.tuesday"),
                t("editMealSchedulePresetPage.wednesday"),
                t("editMealSchedulePresetPage.thursday"),
                t("editMealSchedulePresetPage.friday"),
                t("editMealSchedulePresetPage.saturday"),
              ].map((day, index) => (
                <Tab key={index} label={day} />
              ))}
            </Tabs>
            <Grid container spacing={3} sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <MealSection
                  title={t("editMealSchedulePresetPage.breakfast")}
                  mealSchedule={
                    weeklySchedule.breakfastMeals[
                      [
                        "SUNDAY",
                        "MONDAY",
                        "TUESDAY",
                        "WEDNESDAY",
                        "THURSDAY",
                        "FRIDAY",
                        "SATURDAY",
                      ][currentDay]
                    ]
                  }
                  mealType="BREAKFAST"
                  dayIndex={currentDay}
                  meals={meals}
                  selectedMeals={selectedMeals}
                  handleMealSelect={handleMealSelect}
                  handleCountChange={handleCountChange}
                  canEdit={true}
                />
                <MealSection
                  title={t("editMealSchedulePresetPage.lunch")}
                  mealSchedule={
                    weeklySchedule.lunchMeals[
                      [
                        "SUNDAY",
                        "MONDAY",
                        "TUESDAY",
                        "WEDNESDAY",
                        "THURSDAY",
                        "FRIDAY",
                        "SATURDAY",
                      ][currentDay]
                    ]
                  }
                  mealType="LUNCH"
                  dayIndex={currentDay}
                  meals={meals}
                  selectedMeals={selectedMeals}
                  handleMealSelect={handleMealSelect}
                  handleCountChange={handleCountChange}
                  canEdit={true}
                />
                <MealSection
                  title={t("editMealSchedulePresetPage.dinner")}
                  mealSchedule={
                    weeklySchedule.dinnerMeals[
                      [
                        "SUNDAY",
                        "MONDAY",
                        "TUESDAY",
                        "WEDNESDAY",
                        "THURSDAY",
                        "FRIDAY",
                        "SATURDAY",
                      ][currentDay]
                    ]
                  }
                  mealType="DINNER"
                  dayIndex={currentDay}
                  meals={meals}
                  selectedMeals={selectedMeals}
                  handleMealSelect={handleMealSelect}
                  handleCountChange={handleCountChange}
                  canEdit={true}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Dialog
          open={clearDialogOpen}
          onClose={() => setClearDialogOpen(false)}
          fullWidth
        >
          <DialogTitle>
            {t("editMealSchedulePresetPage.clearSchedule")}
          </DialogTitle>
          <DialogContent>
            <Typography>
              {t("editMealSchedulePresetPage.clearScheduleConfirm")}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setClearDialogOpen(false)} color="primary">
              {t("editMealSchedulePresetPage.cancel")}
            </Button>
            <Button onClick={handleClearSchedule} color="primary">
              {t("editMealSchedulePresetPage.clear")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={copyDialogOpen}
          onClose={() => setCopyDialogOpen(false)}
          fullWidth
        >
          <DialogTitle>
            {t("editMealSchedulePresetPage.copySchedule")}
          </DialogTitle>
          <DialogContent>
            <Typography>
              {t("editMealSchedulePresetPage.copyScheduleConfirm")}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCopyDialogOpen(false)} color="primary">
              {t("editMealSchedulePresetPage.cancel")}
            </Button>
            <Button onClick={handleCopySchedule} color="primary">
              {t("editMealSchedulePresetPage.copy")}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </LocalizationProvider>
  );
};

export default EditMealSchedulePresetPage;
