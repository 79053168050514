import * as React from "react";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { updatePassword } from "aws-amplify/auth";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ResetPasswordDialogProps {
  open: boolean;
  onClose: () => void;
}

const ResetPasswordDialog: React.FC<ResetPasswordDialogProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setPasswordError(true);
      setErrorMessage(t("ResetPasswordDialog.Passwords_Do_Not_Match"));
      return;
    }

    setPasswordError(false);
    setErrorMessage("");

    try {
      await updatePassword({ oldPassword, newPassword });
      handleClose();
      setSuccessSnackbarOpen(true);
    } catch (err: any) {
      setErrorMessage(
        err.message || t("ResetPasswordDialog.Error_Updating_Password"),
      );
    }
  };

  const handleClose = () => {
    onClose();
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setPasswordError(false);
    setErrorMessage("");
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {t("ResetPasswordDialog.Change_Your_Password")}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Box display="flex" flexDirection="column" alignItems="stretch">
            <Typography>
              {t("ResetPasswordDialog.Enter_Old_New_Password")}
            </Typography>
            {errorMessage && (
              <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                {errorMessage}
              </Alert>
            )}
            <TextField
              required
              label={t("ResetPasswordDialog.Old_Password")}
              type={showPassword ? "text" : "password"}
              fullWidth
              margin="normal"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label={t("ResetPasswordDialog.New_Password")}
              type={showPassword ? "text" : "password"}
              fullWidth
              margin="normal"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              error={passwordError && newPassword !== confirmPassword}
              helperText={
                passwordError && newPassword !== confirmPassword
                  ? t("ResetPasswordDialog.Passwords_Do_Not_Match")
                  : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label={t("ResetPasswordDialog.Confirm_Password")}
              type={showPassword ? "text" : "password"}
              fullWidth
              margin="normal"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              error={passwordError && newPassword !== confirmPassword}
              helperText={
                passwordError && newPassword !== confirmPassword
                  ? t("ResetPasswordDialog.Passwords_Do_Not_Match")
                  : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onPaste={(e) => {
                e.preventDefault();
                setErrorMessage(
                  t("ResetPasswordDialog.Please_Retype_Password"),
                );
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}
        >
          <Button variant="outlined" onClick={handleClose}>
            {t("ResetPasswordDialog.Cancel")}
          </Button>
          <Button
            onClick={handleResetPassword}
            color="primary"
            variant="contained"
            sx={{ ml: 2 }}
          >
            {t("ResetPasswordDialog.Change_Password")}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setSuccessSnackbarOpen(false)} severity="success">
          {t("ResetPasswordDialog.Password_Changed_Successfully")}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ResetPasswordDialog;
