import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  CssBaseline,
  Typography,
  Grid,
  Paper,
  Avatar,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import GroupIcon from "@mui/icons-material/Group";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import { format, addWeeks, startOfWeek, endOfWeek } from "date-fns";
import { useTranslation } from "react-i18next";
import { OrderResponse, timezone } from "../config/types";
import endpoints from "../config/apiConfig";
import { utcToZonedTime } from "date-fns-tz";
import colors from "../config/colors";
import apiHelper from "../helper/apiHelper";

interface MealCount {
  [mealId: string]: OrderResponse & { count: number };
}

const Home = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const vendorId = localStorage.getItem("vendorId") ?? "";

  const generateWeekOptions = () => {
    const options = [];
    const today = utcToZonedTime(new Date(), timezone);
    for (let i = -3; i <= 3; i++) {
      const start = startOfWeek(addWeeks(today, i), { weekStartsOn: 0 });
      const end = endOfWeek(addWeeks(today, i), { weekStartsOn: 0 });
      options.push({
        label: `${format(start, "yyyy/MM/dd")} - ${format(end, "yyyy/MM/dd")}`,
        start,
        end,
      });
    }
    return options;
  };

  const weeks = generateWeekOptions();
  const [selectedWeek, setSelectedWeek] = useState(weeks[3]);
  const [data, setData] = useState({
    totalRevenue: 0,
    totalOrders: 0,
    totalCustomers: 0,
    totalMeals: 0,
    trendingItems: [] as (OrderResponse & { count: number })[],
  });

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      if (vendorId) {
        const data = await apiHelper(
          `${endpoints.getOrdersFromDateRange}${vendorId}`,
          {
            method: "POST",
            body: {
              startDate: selectedWeek.start.toISOString(),
              endDate: selectedWeek.end.toISOString(),
            },
          },
        );
        const result: OrderResponse[] = await data;
        processResult(result);
      }
    } catch (error) {
      console.error("Failed to fetch meals:", error);
    } finally {
      setLoading(false);
    }
  }, [vendorId, selectedWeek]);

  const processResult = (result: OrderResponse[]) => {
    const validOrders = result.filter(
      (order) => order.status !== "ORDER_CANCELLED",
    );

    const totalRevenue = validOrders.reduce(
      (acc, order) => acc + order.mealVendorPrice,
      0,
    );

    const mealCount: MealCount = {};
    validOrders.forEach((order) => {
      if (!mealCount[order.mealId]) {
        mealCount[order.mealId] = { ...order, count: 0 };
      }
      mealCount[order.mealId].count += 1;
    });

    const trendingItems = Object.values(mealCount)
      .sort((a, b) => b.count - a.count)
      .slice(0, 5);

    const uniqueMealIds = new Set(validOrders.map((order) => order.mealId));
    const uniqueCustomerIds = new Set(validOrders.map((order) => order.userId));

    setData({
      totalRevenue: totalRevenue,
      totalOrders: validOrders.length,
      totalCustomers: uniqueCustomerIds.size,
      totalMeals: uniqueMealIds.size,
      trendingItems,
    });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ m: 1 }}>
          {t("Overview")}
        </Typography>
        <FormControl variant="outlined" size="medium">
          <InputLabel id="week-select-label">{t("Select_Week")}</InputLabel>
          <Select
            labelId="week-select-label"
            id="week-select"
            value={selectedWeek.label}
            onChange={(event) => {
              const selected =
                weeks.find((week) => week.label === event.target.value) ||
                weeks[0];
              setSelectedWeek(selected);
            }}
            startAdornment={
              <InputAdornment position="start">
                <CalendarTodayIcon />
              </InputAdornment>
            }
            label={t("Select_Week")}
          >
            {weeks.map((week) => (
              <MenuItem key={week.label} value={week.label}>
                {week.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Paper
                sx={{
                  p: 3,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#e3f2fd",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                }}
              >
                <TrendingUpIcon
                  sx={{ fontSize: 40, color: "#1e88e5", mr: 2 }}
                />
                <Box>
                  <Typography variant="h6">
                    ${data.totalRevenue.toFixed(2)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {t("Total_Revenue")}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper
                sx={{
                  p: 3,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#fce4ec",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                }}
              >
                <ReceiptLongIcon
                  sx={{ fontSize: 40, color: "#d81b60", mr: 2 }}
                />
                <Box>
                  <Typography variant="h6">{data.totalOrders}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {t("Total_Orders")}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper
                sx={{
                  p: 3,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#fff3e0",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                }}
              >
                <GroupIcon sx={{ fontSize: 40, color: "#ff9800", mr: 2 }} />
                <Box>
                  <Typography variant="h6">{data.totalCustomers}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {t("Total_Customers")}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper
                sx={{
                  p: 3,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#f1f8e9",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                }}
              >
                <FastfoodIcon sx={{ fontSize: 40, color: "#7cb342", mr: 2 }} />
                <Box>
                  <Typography variant="h6">{data.totalMeals}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {t("Total_Meals")}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>

          <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
            {t("Trending_Items")}
          </Typography>
          <Box sx={{ maxHeight: 400, overflowY: "auto" }}>
            {data.trendingItems.length === 0 ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: "10%",
                }}
              >
                <RemoveShoppingCartIcon
                  sx={{ fontSize: 60, color: "gray", marginBottom: 2 }}
                />
                <Typography variant="h6" color="textSecondary">
                  {t("No_Available_Trending_Items")}
                </Typography>
              </Box>
            ) : (
              data.trendingItems.map((item: any, index: number) => (
                <Paper
                  key={item.mealId}
                  sx={{
                    p: 1,
                    mb: 2,
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                    borderRadius: "8px",
                  }}
                >
                  <Typography variant="h6" sx={{ mr: 2, color: "gray" }}>
                    #{index + 1}
                  </Typography>
                  <Avatar
                    variant="square"
                    src={endpoints.mealImagePrefix + item.imageUrl}
                    alt={item.mealName}
                    sx={{
                      width: 80,
                      height: 80,
                      mr: 2,
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                    }}
                  />
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      {item.mealName}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="body1" sx={{ mr: 1 }}>
                        ${item.mealVendorPrice}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ color: colors.primary, fontWeight: "bold" }}
                      >
                        {t(item.mealType)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ textAlign: "right" }}>
                    <Typography variant="h6">
                      {item.count} {t("orders")}
                    </Typography>
                  </Box>
                </Paper>
              ))
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Home;
