import React from "react";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  MenuList,
  ListItemText,
  Fade,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/auth/authSlice";
import { useNavigate } from "react-router-dom";

interface UserMenuProps {
  anchorEl: null | HTMLElement;
  onClose: () => void;
}

const UserMenu: React.FC<UserMenuProps> = ({ anchorEl, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
    onClose();
  };

  return (
    <Menu
      id="fade-menu"
      MenuListProps={{ "aria-labelledby": "fade-button" }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      TransitionComponent={Fade}
    >
      <MenuList sx={{ width: 150, maxWidth: "100%" }}>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText sx={{ fontFamily: "Poppins", fontWeight: 400 }}>
            Logout
          </ListItemText>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default UserMenu;
