import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Translation files
import enTranslation from "./locales/en.json";
import zhHansTranslation from "./locales/zh-Hans.json";
import zhHantTranslation from "./locales/zh-Hant.json";

const resources = {
  en: {
    translation: enTranslation,
  },
  "zh-Hans": {
    translation: zhHansTranslation,
  },
  "zh-Hant": {
    translation: zhHantTranslation,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
