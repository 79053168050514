import { createTheme } from "@mui/material/styles";
import colors from "./colors";

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.onPrimary,
    },
    background: {
      default: colors.background,
      paper: colors.background,
    },
    text: {
      primary: colors.text,
      secondary: colors.darkText,
    },
  },
});

export default theme;
