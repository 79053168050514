import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  Avatar,
} from "@mui/material";
import { Meal, MealScheduleDetail } from "../config/types";
import QuantityInput from "../components/QuantityInput";
import { colorMap } from "../config/constant";
import endpoints from "../config/apiConfig";
import { useTranslation } from "react-i18next";

interface MealSectionProps {
  title: string;
  mealSchedule: MealScheduleDetail[];
  mealType: "BREAKFAST" | "LUNCH" | "DINNER";
  dayIndex: number;
  meals: Meal[];
  selectedMeals: Record<string, string[][]>;
  handleMealSelect: (
    mealType: "BREAKFAST" | "LUNCH" | "DINNER",
    mealId: string,
    dayIndex: number,
  ) => void;
  handleCountChange: (
    dayIndex: number,
    mealType: "BREAKFAST" | "LUNCH" | "DINNER",
    mealId: string,
    count: number,
  ) => void;
  canEdit: boolean;
}

const MealSection: React.FC<MealSectionProps> = ({
  title,
  mealSchedule,
  mealType,
  dayIndex,
  meals,
  selectedMeals,
  handleMealSelect,
  handleCountChange,
  canEdit,
}) => {
  const { t } = useTranslation();
  return (
    <Card sx={{ mt: 2 }}>
      <CardHeader title={title} sx={{ backgroundColor: "#f0f0f0" }} />
      <CardContent>
        <Typography variant="body2" color="textSecondary">
          {t("MealSection.maxMeals", { mealType: title.toLowerCase() })}
        </Typography>
        {meals
          .filter(
            (meal) =>
              meal.available &&
              meal[mealType.toLowerCase() as "breakfast" | "lunch" | "dinner"],
          )
          .map((meal) => {
            const scheduledMeal = mealSchedule.find(
              (m) => m.mealId === meal.id,
            ) || {
              count: 0,
              mealId: meal.id,
              mealType,
            };
            const isSelected = selectedMeals[mealType][dayIndex].includes(
              meal.id!,
            );
            return (
              <Grid
                container
                alignItems="center"
                spacing={2}
                key={meal.id}
                sx={{
                  mt: 1,
                  pb: 1.5,
                  bgcolor: isSelected ? colorMap[mealType] : "inherit",
                }}
              >
                <Grid item xs={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isSelected}
                        onChange={() =>
                          handleMealSelect(mealType, meal.id!, dayIndex)
                        }
                        disabled={
                          (!isSelected &&
                            selectedMeals[mealType][dayIndex].length >= 2) ||
                          !canEdit
                        }
                      />
                    }
                    label=""
                  />
                </Grid>
                <Grid item xs={2}>
                  <Avatar
                    src={endpoints.mealImagePrefix + meal.imageUrl}
                    alt={meal.name}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Typography sx={{ fontWeight: "bold", color: "#333" }}>
                    {meal.name}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <QuantityInput
                    value={scheduledMeal.count}
                    onChange={(newValue) =>
                      handleCountChange(dayIndex, mealType, meal.id!, newValue)
                    }
                    disabled={!isSelected || !canEdit}
                  />
                </Grid>
              </Grid>
            );
          })}
      </CardContent>
    </Card>
  );
};

export default MealSection;
