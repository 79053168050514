import React from "react";
import { Box, TextField, IconButton } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const QuantityInput: React.FC<{
  value: number;
  onChange: (newValue: number) => void;
  disabled: boolean;
}> = ({ value, onChange, disabled }) => {
  return (
    <Box display="flex" alignItems="center">
      <IconButton
        size="small"
        onClick={() => onChange(Math.max(value - 10, 0))}
        disabled={disabled}
        sx={{ mr: 1 }}
      >
        <RemoveIcon />
      </IconButton>
      <TextField
        type="number"
        value={value.toFixed()}
        onChange={(e) => onChange(Number(e.target.value))}
        disabled={disabled}
        inputProps={{
          style: {
            textAlign: "center",
            padding: "10px 0",
            // Hide the arrows
            MozAppearance: "textfield",
          },
          // For Chrome
          inputMode: "numeric",
        }}
        sx={{
          width: 80,
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              WebkitAppearance: "none",
              margin: 0,
            },
          "& input[type=number]": {
            MozAppearance: "textfield",
          },
        }}
      />
      <IconButton
        size="small"
        onClick={() => onChange(value + 10)}
        disabled={disabled}
        sx={{ ml: 1 }}
      >
        <AddIcon />
      </IconButton>
    </Box>
  );
};

export default QuantityInput;
