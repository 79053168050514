// src/redux/store.ts
import {
  combineReducers,
  configureStore,
  ThunkAction,
  Action,
} from "@reduxjs/toolkit";
import authReducer from "./authSlice";

const rootReducer = combineReducers({
  auth: authReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const preloadedState = {
  auth: {
    token: localStorage.getItem("token") || null,
    user: {
      email: localStorage.getItem("email") || "",
      vendorId: localStorage.getItem("vendorId") || "",
    },
    isAuthenticated: localStorage.getItem("vendorId") !== null,
  },
};

export const store = configureStore({
  reducer: rootReducer,
  preloadedState,
});

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AppDispatch = typeof store.dispatch;
