import React, { useState } from "react";
import {
  Button,
  Typography,
  Box,
  Container,
  TextField,
  IconButton,
  InputAdornment,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { confirmSignIn } from "aws-amplify/auth";
import endpoints from "../config/apiConfig";
import apiHelper from "../helper/apiHelper";
import { useNavigate } from "react-router-dom";

const VendorRegistration: React.FC<{
  email: string;
  onComplete: () => void;
}> = ({ email, onComplete }) => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleCompleteRegistration = async () => {
    if (newPassword.length < 6) {
      setError("Password should be at least 6 characters long.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await confirmSignIn({
        challengeResponse: newPassword,
      });

      await apiHelper(endpoints.confirmStatus, {
        method: "POST",
        body: {
          email,
        },
        bypassTokenCheck: true,
      });

      setOpenDialog(true);
    } catch (error) {
      setError("Failed to set new password.");
    } finally {
      setLoading(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    onComplete();
    navigate("/login");
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        component="h1"
        variant="h5"
        style={{ fontFamily: "Poppins", fontWeight: 600 }}
      >
        Set New Password
      </Typography>
      <Container component="main" maxWidth="xs" sx={{ mt: 2, mb: 2 }}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="New Password"
          type={showPassword ? "text" : "password"}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Confirm Password"
          type={showPassword ? "text" : "password"}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          onPaste={(e) => {
            e.preventDefault();
            setError("Please re-enter your password by typing it in.");
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2, mb: 2 }}
          onClick={handleCompleteRegistration}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Complete Registration"}
        </Button>
        {error && (
          <Box mt={2}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
      </Container>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Registration Successful
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
            Your registration is successful. Please use your new password to log
            in.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default VendorRegistration;
