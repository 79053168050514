import React, { useState } from "react";
import { Menu, MenuItem, ListItemText, Typography, Box } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation } from "react-i18next";
import colors from "../../config/colors";

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
    handleMenuClose();
  };

  return (
    <div>
      <Box
        onClick={handleMenuOpen}
        sx={{ display: "flex", alignItems: "center", cursor: "pointer", mr: 2 }}
      >
        <LanguageIcon fontSize="large" sx={{ mr: 1, color: colors.primary }} />
        <Typography
          sx={{ fontFamily: "Poppins", fontWeight: 600, color: colors.primary }}
        >
          EN | 中
        </Typography>
      </Box>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleLanguageChange("en")}>
          <ListItemText>English</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleLanguageChange("zh-Hant")}>
          <ListItemText>繁體中文</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleLanguageChange("zh-Hans")}>
          <ListItemText>简体中文</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LanguageSelector;
