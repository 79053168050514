import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  Alert,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import TopBar from "../components/TopBar/TopBar";
import onPrimary from "../config/colors";
import { resetPassword, type ResetPasswordOutput } from "aws-amplify/auth";
import { ArrowBack } from "@mui/icons-material";

const ForgotPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  function handleResetPasswordNextSteps(output: ResetPasswordOutput) {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case "CONFIRM_RESET_PASSWORD_WITH_CODE":
        navigate("/reset-password", { state: { username: email } });
        break;
      case "DONE":
        break;
    }
  }

  const handleBack = () => {
    navigate(-1);
  };

  const handleSend = async () => {
    if (!email.trim()) {
      setError(t("Email_address_is_required"));
      return;
    }
    setLoading(true);
    setError(null);
    const username = email;

    try {
      const output = await resetPassword({ username });
      handleResetPasswordNextSteps(output);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else if (
        typeof error === "object" &&
        error !== null &&
        "response" in error
      ) {
        const errResponse = (error as { response: any }).response;
        if (errResponse?.data) {
          setError(errResponse.data);
        } else {
          setError(t("An_unexpected_error_occurred_Please_try_again"));
        }
      } else {
        setError(t("An_unexpected_error_occurred_Please_try_again"));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="landing-page">
      <TopBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "70vh",
          paddingTop: "20vh",
        }}
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            backgroundColor: onPrimary,
            padding: "5vh",
            borderRadius: "15px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            position: "relative",
          }}
        >
          <IconButton
            onClick={handleBack}
            sx={{
              position: "absolute",
              top: "10px",
              left: "10px",
            }}
          >
            <ArrowBack />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              style={{ fontFamily: "Poppins", fontWeight: 600 }}
            >
              {t("Forgot_Password")}
            </Typography>
            <Typography
              component="h2"
              variant="subtitle1"
              style={{ fontFamily: "Poppins", fontWeight: 400, marginTop: 16 }}
            >
              {t("Please_enter_your_email_address")}
            </Typography>
            <Box component="form" sx={{ mt: 2 }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label={t("Email_Address")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading}
                onClick={handleSend}
              >
                {loading ? <CircularProgress size={24} /> : t("Send")}
              </Button>
              {error && (
                <Box mt={2}>
                  <Alert severity="error">{error}</Alert>
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default ForgotPasswordPage;
