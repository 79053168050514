// src/redux/auth/authAPI.ts
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAuthSession } from "aws-amplify/auth";
import endpoints from "../../config/apiConfig";
import apiHelper from "../../helper/apiHelper";

export const loginAsync = createAsyncThunk(
  "auth/login",
  async (credentials: { email: string }) => {
    try {
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      const token = idToken?.toString() ?? "";
      const vendorId = idToken?.payload["custom:vendorId"]?.toString() ?? "";
      const data = await apiHelper(`${endpoints.getVendorInfo}/${vendorId}`, {
        method: "GET",
        token,
      });
      localStorage.setItem("token", token);
      localStorage.setItem("vendorId", vendorId);
      localStorage.setItem("vendorName", data.name);

      return {
        token,
        user: {
          email: credentials.email,
          vendorId,
        },
      };
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("Login failed");
      }
    }
  },
);
