import { jwtDecode } from "jwt-decode";
import i18n from "../config/i18n";

type RequestMethods = "GET" | "POST" | "PUT" | "DELETE";

interface FetchOptions {
  method: RequestMethods;
  body?: any;
  token?: string;
  bypassTokenCheck?: boolean; // Optional flag to bypass token check
}

const isTokenExpired = (token: string) => {
  const decoded: { exp: number } = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  return decoded.exp < currentTime;
};

const apiHelper = async (endpoint: string, options: FetchOptions) => {
  let headers: Record<string, string> = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const token = localStorage.getItem("token") ?? options.token;

  // If token check is not bypassed, perform the check
  if (!options.bypassTokenCheck) {
    if (!token || isTokenExpired(token)) {
      const currentLanguage = i18n.language;
      localStorage.clear();
      i18n.changeLanguage(currentLanguage);
      window.location.href = "/"; // Redirect to home page
      return Promise.reject(new Error("Token expired or not found"));
    }
  }

  if (token) {
    headers["Authorization"] = `${token}`;
  }

  let config: any = {
    method: options.method,
    headers: headers,
  };

  if (["POST", "PUT"].includes(options.method)) {
    config.body = JSON.stringify(options.body);
  }

  try {
    const response = await fetch(endpoint, config);
    const rawResponseText = await response.text();

    if (!response.ok) {
      throw new Error(rawResponseText || "Request failed");
    }

    let responseData;
    try {
      responseData = JSON.parse(rawResponseText);
    } catch (parseError) {
      responseData = { message: rawResponseText };
    }

    return responseData;
  } catch (error) {
    console.error("Error making API request:", error);
    throw error;
  }
};

export default apiHelper;
