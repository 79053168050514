import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../redux/auth/store";

interface PrivateRouteProps {
  children: JSX.Element; // Define children as a single React element
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const vendorId = useSelector((state: RootState) => state.auth.user?.vendorId);
  return vendorId ? children : <Navigate to="/landing" replace />;
};

export default PrivateRoute;
